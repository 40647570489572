.SignupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    padding: 3vw;
}

.SignupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    width: 100%;
    background-color: var(--page-background);
}

.HeadingThree,
.HeadingTwo {
    font-family: IRANSans, serif;
    color: var(--primary-dark-color);
}

.HeadingTwo {
    font-size: 8vw;
    margin-bottom: 0;
}

.HeadingThree {
    font-size: 5vw;
    margin-bottom: 0;
}

.InfoHeading {
    font-size: 5vw;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
}

.SignupForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
}

.PersonalInfoContainer,
.UserInfoContainer {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.InputField {
    font-family: IRANSans, serif;
    margin-bottom: 4vw;
    padding: 2vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
}

.ButtonField,
.LoginButtonField {
    font-family: IRANSans, serif;
    font-size: 5vw;
    font-weight: bold;
    margin-top: 4vw;
    padding: 1vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    background-color: var(--primary-light-color);
    color: white;
    width: 90%;
}

.ButtonField:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.LoginButtonField {
    background-color: var(--primary-dark-color);
    width: 85%;
    margin-bottom: 15vw;
}

.TextField {
    font-family: IRANSans, serif;
    font-size: 4vw;
    border-top: 1px solid var(--primary-dark-color);
    color: var(--primary-dark-color);
    text-align: center;
    width: 85%;
    margin-top: 8vw;
    padding-top: 5vw;
}