.sellers_section_container {
    display: flex;
    flex-direction: row;
    width: 85%;
    height: 100%;
    gap: 1vw;
}

.sellers_section_first_col,
.sellers_section_second_col {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1vw;
}

.sellers_section_first_col {
    width: 40%;
}

.sellers_section_second_col {
    width: 60%;
}

.ranking_table_container {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    width: 100%;
    box-sizing: border-box;
    height: 40%;
}

.pie_chart,
.smaller_pie_chart {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    box-sizing: border-box;
    height: 100%;
}

.smaller_pie_chart {
    height: 60%;
}

.pie_chart_body {
    margin-top: 1vw;
    align-self: center;
}

.horizontal_pie_chart_body {
    padding: 0;
    box-sizing: border-box;
}

.horizontal_pie_chart_body canvas {
    max-width: 100%;
    max-height: 100%;
}

.pie_chart_body canvas {
    max-width: 100%;
    max-height: 100%;
}

.seller_select_container {
    display: flex;
    gap: 1vw;
    align-items: center;
    width: max-content;
    margin-right: auto;
}