.statistic_container,
.half_size_statistic_container,
.mini_statistic_container {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    box-sizing: border-box;
}

.statistic_container {
    width: 33%;
}

.half_size_statistic_container {
    width: 50%;
}

.mini_statistic_container {
    height: 50%;
    width: 100%;
    /*align-items: center;*/
    /*justify-content: center;*/
}

.statistic_text,
.mini_statistic_text {
    align-self: flex-end;
    font-size: 2vw;
    font-weight: bold;
    font-family: IRANSansFaNum, serif;
    color: var(--primary-light-color);
    margin-left: 1vw;
}

.mini_statistic_text {
    margin-top: 1.5vw;
}

.section_header_container {
    display: flex;
    align-items: center;
    font-size: 1.2vw;
    font-weight: bold;
    color: var(--darkGray-color);
    gap: 0.5vw;
}

.section_header {
    font-size: 1.1vw;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
    font-weight: bold;
}

.section_icon {
    font-size: 1.3vw;
    color: var(--primary-dark-color);
}

.loading_container {
    display: grid;
    place-items: center;
    height: 100%;
}

.profit_container {
    display: flex;
    justify-content: space-around;
}

.display_mode_dropdown {
    font-family: IRANSansFaNum, serif;
    margin-left: auto;
    background-color: white;
    border: 1px solid var(--grey-color);
    padding-right: 5px;
    padding-left: 5px;
    font-size: 0.9vw;
    color: var(--darkGray-color);
    border-radius: 5px;
    cursor: pointer;
    height: 65%;
    align-self: center;
}

.trend_chart {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    width: 100%;
    box-sizing: border-box;
    height: 100%;
}

.trend_chart_header {
    width: 100%;
}

.trend_chart_body {
    margin-top: 1vw;
    height: 85%;
}

.pie_chart_body {
    margin-top: 1vw;
    align-self: center;
}

.pie_chart_body canvas {
    max-width: 100%;
    max-height: 100%;
}

.ranking_table_container,
.half_size_ranking_table_container,
.full_size_ranking_table_container {
    display: flex;
    flex-direction: column;
    padding: 0.5vw 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    width: 50%;
    box-sizing: border-box;
}

.half_size_ranking_table_container {
    width: 50%;
}

.full_size_ranking_table_container {
    width: 100%;
}

.ranking_table {
    margin-top: 1vw;
}

.filter_select_container {
    display: flex;
    align-items: center;
}

.filter_select_subject {
    font-weight: bold;
    color: var(--darkGray-color);
}

.filters_select {
    cursor: pointer;
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--grey-color);
    border-color: var(--grey-color);
    border-radius: 10px;
    width: 100%;
    font-size: 1vw;
}

.filters_select:focus {
    outline: none;
    border-color: var(--primary-light-color);
    box-shadow: 0 0 0 1px var(--primary-light-color);
}