.LoginContainer {
    position: relative;
    display: flex;
    align-items: center;
    /* Align items vertically */
    justify-content: center;
    /* Center content horizontally */
    height: 100vh;
    /* Adjust this to fit your design */
}

.LoginContent {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: var(--page-background);
    z-index: 1;
    /* Ensure it's above the image */
}

img {
    position: relative;
    z-index: 0;
    /* Place image behind the LoginContainer */
}

.HeadingThree,
.HeadingTwo {
    font-family: IRANSans, serif;
    color: var(--primary-dark-color);
}

.HeadingTwo {
    font-size: 2vw;
}

.HeadingThree {
    font-size: 1.5vw;
    padding-top: 5vw;
}

.AuthForm {
    display: flex;
    flex-direction: column;
    width: 90%;
    align-items: center;
}

.InputField {
    font-family: IRANSans, serif;
    width: 50%;
    margin-bottom: 1vw;
    padding: 1vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
}

.ButtonField,
.RegisterButtonField {
    cursor: pointer;
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    width: 30%;
    margin-top: 1.5vw;
    padding: 0.5vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    background-color: var(--primary-light-color);
    color: white;
}

.ButtonField:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.RegisterButtonField {
    background-color: var(--primary-dark-color);
    width: 27%;
}

.TextField {
    font-family: IRANSans, serif;
    font-size: 1vw;
    color: var(--primary-dark-color);
    text-underline: var(--primary-dark-color);
    border-top: 1px solid var(--primary-dark-color);
    text-align: center;
    width: 50%;
    margin-top: 2vw;
    padding-top: 2vw;
}