.overview_section_container {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    gap: 1vw;
}

.overview_section_first_row,
.overview_section_second_row,
.overview_section_third_row {
    display: flex;
    gap: 1vw;
    width: 100%;
    justify-content: stretch;
}

.overview_section_first_row {
    height: 15%;
}

.overview_section_second_row {
    height: 45%;
}

.overview_section_third_row {
    height: 40%;
}

.cards_column_container {
    display: flex;
    flex-direction: column;
    gap: 1vw;
    height: 100%;
    flex: 1;
    width: 10%;
}

