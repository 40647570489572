.main_container {
    display: flex;
    flex-direction: column;
    width: 96%;
    direction: rtl;
    margin: 0 auto;
    height: 100%;
    /* centers the container horizontally */
}

.top_bar {
    display: flex;
    align-items: center;
    width: 100%;
    direction: rtl;
    gap: 1.2vw;
    min-height: 8%;
}

.header_title {
    font-size: 1.2vw;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
    font-weight: bold;
}

.vertical_divider {
    display: flex;
    align-items: center;
    width: 2px;
    height: 70%;
    background-color: var(--primary-dark-color);
}

.reports_and_filters_container {
    display: flex;
    gap: 1vw;
    width: 100%;
    height: 100%;
    margin-top: 1vw;
    direction: rtl;
}

.header_button,
.filter_button,
.reset_filter_button {
    font-size: 1.1vw;
    font-family: IRANSans, serif;
    border-radius: 5px;
    border: 1px solid var(--primary-dark-color);
    width: 10%;
    height: 65%;
    cursor: pointer;
    background-color: white;
    color: var(--primary-dark-color);
    font-weight: normal;
}

.header_button:disabled {
    font-weight: bold;
    background-color: var(--primary-dark-color);
    border: 1px solid var(--primary-dark-color);
    color: white;
}

.filters_panel {
    display: flex;
    flex-direction: column;
    direction: rtl;
    padding: 1vw;
    background-color: white;
    border: 1px solid var(--grey-color);
    border-radius: 10px;
    font-family: IRANSans, serif;
    gap: 1vw;
    width: 15%;
    height: 100%;
    box-sizing: border-box;
}

.reports {
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    gap: 1vw;
}

.section_header_container {
    display: flex;
    align-items: center;
    font-size: 1.2vw;
    font-weight: bold;
    color: var(--darkGray-color);
    gap: 0.5vw;
}

.section_header {
    font-size: 1.1vw;
    color: var(--primary-dark-color);
    font-family: IRANSans, serif;
    font-weight: bold;
}

.section_icon {
    font-size: 1.3vw;
    color: var(--primary-dark-color);
}

.buttons_container {
    gap: 0.5vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: auto; /* This pushes the container to the bottom */
}

.filter_button,
.reset_filter_button {
    font-size: 1vw;
    font-weight: bold;
    width: 95%;
    height: 100%;
}

.filter_button {
    background-color: var(--primary-light-color);
    border: 1px solid var(--primary-light-color);
    color: white;
}

.reset_filter_button {
    color: var(--primary-light-color);
    border: 1px solid var(--primary-light-color);
}

.filters_selects_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 1vw;
    justify-content: space-between;
    gap: 0.5vw;
}

.filter_select_container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5vw;
}

.filter_select_subject {
    font-weight: bold;
    color: var(--darkGray-color);
}

.filters_select {
    cursor: pointer;
    direction: rtl;
    font-family: IRANSans, serif;
    color: var(--grey-color);
    border-color: var(--grey-color);
    border-radius: 10px;
    width: 100%;
    font-size: 1vw;
}

.filters_select:focus {
    outline: none;
    border-color: var(--primary-light-color);
    box-shadow: 0 0 0 1px var(--primary-light-color);
}

.customDatePicker {
    padding: 5px;
    border: 1px solid var(--grey-color);
    border-radius: 5px;
    font-family: IRANSans, serif;
    font-size: 1vw;
    background-color: white;
    width: 100%;
}

.customDatePicker:focus {
    outline: none;
    border-color: var(--primary-light-color);
    box-shadow: 0 0 0 1px var(--primary-light-color);
}

.customDatePickerDropdown {
    flex-grow: 1;
    display: flex;
}

.lists_container {
    display: flex;
    padding: 0.5vw 1vw;
}