.SignupContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    overflow: hidden;
}

.SignupContent {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    direction: rtl;
    right: 0;
    width: 50%;
    height: 100%;
    background-color: var(--page-background);
    z-index: 1;
    overflow-y: auto;
    padding-bottom: 2vw;
    box-sizing: border-box;
}

img {
    position: relative;
    z-index: 0;
    /* Place image behind the LoginContainer */
}

.HeadingThree,
.HeadingTwo {
    font-family: IRANSans, serif;
    color: var(--primary-dark-color);
    font-size: 1.5vw;
}

.HeadingTwo {
    font-size: 1.5vw;
    margin-bottom: 0;
}

.HeadingThree {
    font-size: 1.5vw;
    padding-top: 1.5vw;
    margin-bottom: 0;
}

.SignupForm {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: center;
}

.PersonalInfoContainer,
.UserInfoContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: center;
}

.InfoHeading {
    font-size: 1.25vw;
    color: var(--primary-dark-color);
    direction: rtl;
    align-self: flex-start;
    margin-top: 0.5vw;
    font-family: IRANSans, serif;
}

.InputField {
    font-family: IRANSans, serif;
    width: 100%;
    margin-bottom: 1vw;
    padding: 0.5vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
}

.ButtonField,
.LoginButtonField {
    cursor: pointer;
    font-family: IRANSans, serif;
    font-size: 1vw;
    font-weight: bold;
    width: 20%;
    margin-top: 0.5vw;
    padding: 0.5vw;
    border-radius: 10px;
    border: 1px solid var(--grey-color);
    background-color: var(--primary-dark-color);
    color: white;
}

.ButtonField:disabled {
    background-color: var(--grey-color);
    color: var(--primary-dark-color);
    cursor: not-allowed;
}

.LoginButtonField {
    background-color: var(--primary-light-color);
    width: 20%;
}

.TextField {
    font-family: IRANSans, serif;
    font-size: 1vw;
    color: var(--primary-dark-color);
    border-top: 1px solid var(--primary-dark-color);
    text-align: center;
    width: 50%;
    margin-top: 1.5vw;
    padding-top: 1.5vw;
}